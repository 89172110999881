import React from "react";


import { Grid,Box,Typography } from "@mui/material";

import img1 from './images/heroimage.png';

import img2 from './images/slider1.jpg';
import img3 from './images/slider2.jpg';
import img4 from './images/slider3.jpg';
//swiper js
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from 'swiper';



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const HomeContent = () => {



   






    return(
        <React.Fragment>


              {/* carousel */}


              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                 <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    autoplay
    >
     
      <SwiperSlide><img src={img2} width="100%" height="auto"></img></SwiperSlide>
      <SwiperSlide><img src={img3} width="100%" height="auto"></img></SwiperSlide>
      <SwiperSlide><img src={img4} width="100%" height="auto"></img></SwiperSlide>
    </Swiper> 














                </Grid>
            </Grid>



            {/* end of carousel */}





              {/* welcome content */}
              <Grid container spacing={4}>
                {/* 1st half */}
               
            <Grid item md={8} sm={6} xs={12}>
            <Box sx={{width:'100%',height:'auto',mb:'25px',mt:'25px'}}>
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box sx={{m:'25px'}}>
                            <Typography variant="h3" className="welcomecontent">Welcome !</Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12} sm={6} xs={12}>
                        <Box sx={{pl:'25px',pr:'25px'}}>
                            <Typography variant="subtitle1" className="hero1">
                            Abirami Enterprises! Leading trader in water pump mechanical components, through offering all company type pump spares within your reach. Besides being a complete source of mechanical components expertise, we are distributors for water pump manufacturing Industries.
                            We are having range of products like MS Cover, Cooling Fan, Impeller, Terminal Board, Connector, Terminal Boxes, Bush, Mechanical and Industrial Seals etc.,
                            Our vast experience for more than 30 years in water pump mechanical components being one among the leading Traders in Coimbatore we focus on the satisfaction of growing needs for tuning our products and delivery as per customer needs. 
                            </Typography>
                        </Box>
                    </Grid>
                 
                </Grid>

                </Box>
            </Grid>
   
                {/* end of c1st half */}
                {/* 2nd half */}
            <Grid item md={4} sm={6} xs={12}>
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{display:'flex',height:'400px',alignItems:'center',mb:'25px',mt:'25px',flexDirection:'column'}}>
                    <img src={img1} width="400px" height="300px" style={{opacity:'0.2'}}></img>
                    <span style={{color:'#931B1E'}}>A b i r a m i E n t e r p r i s e s</span>
                     </Box>
                    </Grid>
                </Grid>
            </Grid>
                {/* end of 2nd half */}
            </Grid>
           {/* end of welcome content */}





            </React.Fragment>
    );
}

export default HomeContent;