
import './App.css';
import react from 'react';


//importing files
import Home from './Home';





function App() {
  return (
   <react.Fragment>
      <Home/> 
   </react.Fragment>
  );
}

export default App;
