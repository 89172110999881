import { Typography,Grid,Box, Container, Button } from "@mui/material";
import React from "react";



import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Tooltip from '@mui/material/Tooltip';

// icons
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import NavigationIcon from '@mui/icons-material/Navigation';

//css
import './contactus.css';




const Contactus = () => {

    const whatsapppage = () => {
        window.open("https://api.whatsapp.com/send?phone=919443420311");
    }

    const mailus = () => {
        window.location.href="mailto:cbeabiramienterprises@gmail.com?subject=Subject&body=message%20goes%20here"
    }

    const call = () => {
        window.open("tel:+919443420311")
    }

    return(
        <React.Fragment>
            <Box className="ContactusStyle">
                <Typography variant="h3" style={{marginBottom:'25px',fontFamily:'Playfair Display'}}>Contact us</Typography>

               


                <Container>
               <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={6}>
                <div className="box1">
                <div className="boxlogo">
                {/* <img src={chaticon} width="64px" height="64px"></img> */}
                <ChatBubbleOutlineIcon style={{fontSize:'50px',color:'#fff'}}/>
                </div>
                <div className="boxtext">
                <Typography variant="p" style={{color:'#fff'}}>Let's Have a Chat</Typography>
                </div>
                <div className="boxbutton">
                <Button variant="contained" className="boxbuttonstyle" onClick={whatsapppage}>Start a Live Chat</Button>
                </div>  
                </div>
                </Grid>
                <Grid item md={3}sm={6} xs={6}>
                <div className="box1">
                <div className="boxlogo">
                 {/* <img src={mailicon} width="64px" height="64px"></img> */}
                 <MailOutlineIcon style={{fontSize:'50px',color:'#fff'}}/>
                </div>
                <div className="boxtext">
                <Typography variant="p" style={{color:'#fff'}}>Drop us a Line</Typography>
                </div>
                <div className="boxbutton">
                <Button variant="contained" className="boxbuttonstyle" onClick={mailus}>Submit a Request</Button>
                </div> 
                </div>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                <div className="box1">
                <div className="boxlogo">
                {/* <img src={phoneicon} width="64px" height="64px"></img> */}
                <PhoneAndroidIcon style={{fontSize:'50px',color:'#fff'}}/>
                </div>
                <div className="boxtext">
                <Typography variant="p" style={{color:'#fff'}}>Call us</Typography>
                </div>
                <div className="boxbutton">
                <Button variant="contained" className="boxbuttonstyle" onClick={call}>give us a call</Button>
                </div> 
                </div>
                </Grid>
                <Grid item md={3} sm={6} xs={6}>
                <div className="box1">
                <div className="boxlogo">
                {/* <img src={locationicon} width="64px" height="64px"></img> */}
                <NavigationIcon style={{fontSize:'50px',color:'#fff'}}/>
                </div>
                <div className="boxtextaddress">
                <Typography variant="p" style={{color:'#fff',padding:'15px',textAlign:'center'}}>1052, Bharathiyar Road,P.N.Palayam,<br></br> 
                    Coimbatore, Tamil Nadu,<br></br>  INDIA – 641 037
                </Typography>
                </div>
                </div>
                </Grid>
               </Grid>
               </Container>
            </Box>

            

            <Box className="addressBox">
            <Typography variant="h3" style={{textAlign:'center',fontFamily:'Playfair Display'}}>Address</Typography>
            <Box sx={{m:'25px'}}>
                <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250488.99879059353!2d77.0122067822346!3d11.195729081815958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858f8d4a43d3d%3A0x2fe35a43fbeeb766!2sAbirami%20Enterprises%20%7C%20Motor%20%26%20Pump%20Spares!5e0!3m2!1sen!2sin!4v1644916691533!5m2!1sen!2sin" width="100%" height="300px" style={{border:0}}></iframe>
                </Grid>
                </Grid>
            </Box>
            </Box>

        </React.Fragment>
    );
}

export default Contactus;