import React from 'react'

import { Grid,Box,Typography } from '@mui/material';

const Footer = () => {
    return(
        <React.Fragment>
                  {/* footer */}
             <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                    <Box className="footer">
                      <Typography style={{color:'#fff'}} variant="body1">Copyright © AbiramiEnterprises.2022</Typography>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Footer;