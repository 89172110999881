import { AppBar, Toolbar,Grid,Box, Typography,List,Button,Drawer } from "@mui/material";
import React from "react";


// logo and images
import logo from './Logo/logo.png'; 



//importing css
import './home.css';


//material icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MenuIcon from '@mui/icons-material/Menu';

import { BrowserRouter,Routes, Route,Link } from "react-router-dom";


//import route files
import Aboutus from "./Aboutus";
import HomeContent from "./homeContent";
import OurProducts from "./Products";
import Footer from "./Footer";
import Contactus from "./Contactus";











const Home = () => {

    const homepage = () => {
        window.location.href="/"
    }
    const aboutpage = () => {
        window.location.href="About"
    }

    const productpage = () => {
        window.location.href="/Products"
    }

    const contactpage = () => {
        window.location.href="/Contact"
    }



    const [state, setState] = React.useState({
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    
      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List style={{display:'flex',width:'100%',flexDirection:'Column',height:'100vh',justifyContent:'center',alignItems:'right',backgroundColor:'#fff'}}>
              <img src={logo} onClick={homepage} width="90px" height="90px"></img>
            <span style={{margin:'10px'}} className="sidemenulinst" onClick={homepage}>HOME</span>
            <span style={{margin:'10px'}} className="sidemenulinst" onClick={aboutpage}>ABOUT US</span>
            <span style={{margin:'10px'}} className="sidemenulinst" onClick={productpage}>PRODUCTS</span>
            <span style={{margin:'10px'}} className="sidemenulinst" onClick={contactpage}>CONTACT US</span>
          </List>
        </Box>
      );
    




return(
        <React.Fragment>

            {/* appbar */}
            <BrowserRouter>
            <AppBar position="relative">
                <Toolbar style={{backgroundColor:'#fff'}}>
                    <Grid container>
                        <Grid item md={5} sm={5} xs={5}>
                          <div style={{display:'inline-flex',verticalAlign:'middle'}}>
                            <img src={logo} style={{cursor:'pointer'}} onClick={homepage} width="70px" height="100px"></img><span className="abiramientrprisetext" style={{color:'#931B1E',display:'flex',alignItems:'center',height:'104px'}}>A b i r a m i E n t e r p r i s e s</span>
                            </div>






                        </Grid>
                        <Grid item md={7} sm={7} xs={7}>
                            <Box className="navigationbarcontainerstyle">
                             
                            <ul>
                                <li><Link to="/" id="menulist">HOME</Link></li>
                                <li><Link to="About" id="menulist">ABOUT US</Link></li>
                                <li><Link to="Products" id="menulist">PRODUCTS</Link></li>
                                <li><Link to="Contact" id="menulist">CONTACT US</Link></li>
                            </ul>
                          
                        
                          

                            </Box>

                            <div className="menucontainerinresponsivemode">
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
        
            <MenuIcon style={{color:'#931b1e',cursor:'pointer'}}  className="menubtn" onClick={toggleDrawer(anchor, true)}/>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
                        </Grid>

                    </Grid>
                </Toolbar>
            </AppBar>




            {/* responsive menu (drawer) */}


          




        <Routes>
        <Route path="/" element={<HomeContent />} />
        <Route path="/About" element={<Aboutus />} />
        <Route path="/Products" element={<OurProducts />} />
        <Route path="/Contact" element={<Contactus />} />
      </Routes>
          



          
        <Footer/>
           


            </BrowserRouter>

           

        </React.Fragment>
    );
}

export default Home;