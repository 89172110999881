import { Typography,Grid,Box,Card,CardMedia,CardContent,Divider } from "@mui/material";
import React from "react";

import './product.css';



//images
import product1 from './images/terminalboard.jpg';
import product2 from './images/motor cooling fan.png';
import product3 from './images/motor cooling cover.png';
import product4 from './images/Mechanical Seal.png';
import product5 from './images/impller.png';
import product6 from './images/motor coil.png';
import product7 from './images/Piston Pump Spares.png';
import product8 from './images/Bush.png';
import product9 from './images/Terminal Boxes_PVC.png';
const OurProducts = () => {
    return(
        <React.Fragment>
            <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{textAlign:'center',m:'25px'}}>
                    <Typography variant="h4" style={{fontFamily:'Playfair Display'}}>Products</Typography>
                    </Box>
                </Grid>
            </Grid>


            <Grid container>
                <Grid item md={3} sm={6} xs={12}>
                    <Box sx={{m:'25px'}}>
                    <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product1}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Terminal Board (Brass/SS/MS (Nickel Plating))</Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Complete Choice of Product which includes all type of Terminal connector in Baklite/DMC material with MS, Brass Bolt and nut Upto 200HP Motor. </p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box sx={{m:'25px'}}>
                <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230px"
        width="100%"
        image={product3}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Motor Cooling Cover </Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Offering you a complete choice of products which include Motor Fan Cover for Single and Three Phase industrial Motor </p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box sx={{m:'25px'}}>
                <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product2}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Motor Cooling Fan (PVC / Aluminium)</Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Cooling Fan available for all type motor in PVC and Aluminium material.</p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box sx={{m:'25px'}}>
                <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product4}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Water & Industrial type Mechanical Seal </Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Focused to offer you at one stop, we are dealing with all type of Industrial and mechanical seal with wide range of brand. </p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
            </Grid>






        {/* products 2nd row */}



        <Grid container>
                <Grid item md={3} sm={6} xs={12}>
                    <Box sx={{m:'25px'}}>
                    <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product5}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Impeller (Gunmetal/ Noryl/ Aluminium)</Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>All Stage and Monoblock/ Self primming /Open well impeller is available in Gunmetal/Noryl and aluminium material. </p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box sx={{m:'25px'}}>
                <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230px"
        width="100%"
        image={product6}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Motor Coil Winding Machine & Head </Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Coil Rewinding machine and respective bobbin are available  </p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box sx={{m:'25px'}}>
                <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product7}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Piston pump spares</Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Piston pump spares (1x3/4, 11/4x1) for Suguna and Kumar are available</p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box sx={{m:'25px'}}>
                <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product8}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Bush (Gunmetal/SS) </Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>For All type of Monoblock pump bushes are available</p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
            </Grid>







            <Grid container>
                <Grid item md={3} sm={6} xs={12}>
                    <Box sx={{m:'25px'}}>
                    <Card className="productcardstyle">
      <CardMedia 
      className="productsimagestyle"
        component="img"
        height="230"
        width="100%"
        image={product9}
        alt="green iguana"
      />
      <CardContent className="productscardcontent">
      <Box sx={{width:'100%',height:'70px',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography variant="h6">Terminal Boxes (PVC / MS / Aluminium)</Typography>
        </Box>
        <Divider/>
        <Box sx={{width:'100%',height:'150px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <p style={{textAlign:'justify'}}>Connector cover are available in PVC/Aluminium and MS material. </p>
          </Box>
      </CardContent>
    </Card>
    </Box>
                </Grid>
               
                
              
            </Grid>





        </React.Fragment>
    );
}

export default OurProducts;