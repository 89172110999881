import React from "react";

import { Grid,Box,Paper, Typography, Container } from "@mui/material";

import img1 from './images/aboutusimage.jpg';

//import css style
import './aboutus.css';

const Aboutus  = () => {
    return(
        <React.Fragment>
            <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                    <img src={img1} width="100%" height="500px"></img>
                </Grid>
            </Grid>
        <Container>
            <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{p:'25px',mt:'-100px'}}>
                        <Paper className="aboutuspaperstyle" elevation={8}>
                        <Grid container>
                            <Grid item md={3} sm={6} xs={12}>
                                <Box className="aboutuspapercontentstyle">
                                <Typography variant="h6">Year of Establishment</Typography>
                                <Typography variant="body1">1984</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                            <Box className="aboutuspapercontentstyle">
                            <Typography variant="h6">Owned by</Typography>
                            <Typography variant="body1">RMK. Chidambaram</Typography>
                            </Box>
                            </Grid>
                <Grid item md={3} sm={6} xs={12}>
                <Box className="aboutuspapercontentstyle">
                <Typography variant="h6">Company GST No</Typography>
                <Typography variant="body1">33ACVPC9001G1ZS</Typography>
                </Box>
                </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                    <Box className="aboutuspapercontentstyle">
                <Typography variant="h6">Nature of Business</Typography>
                <Typography variant="body1">Distributor/Retailer</Typography>
                </Box>
                </Grid>
                        </Grid>


                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Container>


        <Container>

            <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{ml:'25px',mr:'25px',mt:'25px',textAlign:'center'}}>
                    <Typography variant="h3" style={{fontFamily:'Playfair Display'}}>About us</Typography>
                    </Box>
                </Grid>
            </Grid>

        </Container>

        <Container>
            <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                <Box sx={{m:'25px'}}>
                    <p className="aboutuscontent">We Abirami Enterprises established on 1984. We are one of the primary distributors/retailers of Water Pump mechanical components and easy to handle motor spares and piston pump spares. Infused with the aim to deal in best quality Motor spares mechanical components. We, at Abirami Enterprises, are the best water pump components provider within your reach. Today we are the authorized distributors of leading companies. We have made a continuous improvement in the supply of various genuine and trusted quality motor Spares. To meet the ever-increasing market requirements.</p>
                    </Box>
                </Grid>
            </Grid>

            </Container>
        </React.Fragment>
    );
}

export default Aboutus;